<template>
  <div class="flex flex-wrap">
   <div class="w-full mb-12 px-4">
      <transaction-table />
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import TransactionTable from "@/components/Cards/TransactionTable.vue";
import MapExample from "@/components/Maps/MapExample.vue";

export default {
  components: {
    MapExample,
    TransactionTable
  },
};
</script>
